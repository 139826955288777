import React, { useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export const useBlockNavigation = (shouldBlock) => {
    const { navigator } = React.useContext(UNSAFE_NavigationContext);

    useEffect(() => {
        if (!shouldBlock) return;

        const unblock = navigator.block((tx) => {
            if (window.confirm("The quote is still under review. Are you sure you want to leave?")) {
                unblock();
                tx.retry();  // Proceed with navigation if confirmed
            }
        });

        return unblock;
    }, [navigator, shouldBlock]);
};